import {
  PickleballCourt,
  Place,
  PlaceFollower,
  PlaceGroup,
  PlaceType,
} from '@index/interfaces';

import { GthModel } from './model';

export class GthPlaceModel extends GthModel<Place> {
  constructor(id: string, model: Place) {
    super(id, model);
  }

  get id() {
    return this.model.id;
  }

  get pendingChanges() {
    return this.model.pendingChanges;
  }

  get title() {
    return this.model?.title ?? '';
  }

  get email() {
    return this.model?.email ?? '';
  }

  get phone() {
    return this.model?.phone ?? '';
  }

  get website() {
    return this.model?.website ?? '';
  }

  get lastUpdatedBy() {
    return this.model?.lastUpdatedBy ?? '';
  }

  get originalRendition() {
    // reviewAccepted and originalRendition set: Updated is under review
    // reviewAccepted and originalRendition empty: New is under review
    return this.model?.originalRendition;
  }

  get reviewAccepted() {
    return !!this.model?.reviewAccepted;
  }

  get deniedReview() {
    return !!this.model?.deniedReview;
  }

  get address() {
    return this.model.address;
  }

  get description() {
    return this.model.description ?? '';
  }

  get pickleballMetadata() {
    return this.model.pickleballMetadata;
  }

  get type() {
    if (this.pickleballMetadata) {
      return PlaceType.PickleBall;
    }
  }

  private _visitors?: string[];

  get visitors(): string[] | undefined {
    return this._visitors ?? [];
  }

  set visitors(val: string[]) {
    this._visitors = val;
  }

  private _followers?: PlaceFollower[];

  get followers(): PlaceFollower[] | undefined {
    return this._followers ?? [];
  }

  set followers(val: PlaceFollower[]) {
    this._followers = val;
  }

  private _groups?: PlaceGroup[];

  get groups() {
    return this._groups ?? [];
  }

  set groups(val: PlaceGroup[]) {
    this._groups = val;
  }

  getImage(num = 0) {
    if (Array.isArray(this.model.images)) {
      return this.model.images[num];
    }
    return undefined;
  }
}

export class GthPickleballPlaceModel extends GthPlaceModel {
  // cast pickleballMetadata as required instead of optional
  constructor(id: string, model: Place & { pickleballMetadata: PickleballCourt }) {
    super(id, model);
  }

  get lines() {
    return this.pickleballMetadata.lines;
  }

  get nets() {
    return this.pickleballMetadata.nets;
  }

  get indoorCourts() {
    return this.pickleballMetadata.indoorCourts;
  }

  get outdoorCourts() {
    return this.pickleballMetadata.outdoorCourts;
  }
}
